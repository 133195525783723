<template>
  <div :class="[job.status === 'Draft' && this.$route.name === 'RecruiterDashboard' ? 'tw-cursor-pointer' : '']" @click="updateJob">
    <div class="d-flex tw-flex-col md:tw-flex-row my-3 pa-5 jobCard justify-space-between">
      <div>
        <div class="d-flex">
          <h3 class="jobTitle tw-text-enumGrey2">{{ job.title }}</h3>
          <div class="py-1 px-2 ml-4 status  sm:tw-block tw-hidden">
            <h4 class="jobStatus tw-text-enumGrey2">{{ job.status }}</h4>
          </div>
        </div>
        <div class="d-flex align-center mt-1 ">
          <h4 class="jobLevelAndLocation tw-text-enumGrey2">{{ job.experience_level }}</h4>
          <v-avatar class="mx-2" size="7" color="#8F47F4"></v-avatar>
          <h4 class="jobLevelAndLocation tw-text-enumGrey2">{{ job.city }}, {{job.state}}</h4>
        </div>

      </div>
      <div class="sm:tw-block tw-hidden">
        <h2 class="jobCreated tw-text-enumGrey3">{{ processedDate(job.created_at) }}</h2>
      </div>

      <div class=" sm:tw-hidden mt-5 tw-flex justify-space-between align-center">
        <div class="">
          <h2 class="jobCreated tw-text-enumGrey3">{{ processedDate(job.created_at) }}</h2>
        </div>
        <div class="py-1 px-2 ml-4 status  ">
          <h4 class=" jobStatus tw-text-enumGrey2 ">{{ job.status }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "JobCard",
  props: {
    job: Object,
    search: String

  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,

      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "organization"
        },
        {text: " ", value: "product"},
        {text: " ", value: "subscription"},
        {text: "", value: "amount"},
        {text: "", value: "due_date"},


      ],
      getTraineePayment: [
        {
          id: 1,
          organization: 'Semicolon',
          product: 'Teach, Track & Apply',
          product_access: 'Teach(Primary)',
          subscription: 'Monthly',
          amount: '120,000',
          due_date: '27/05/2021'
        },
        {
          id: 2,
          organization: 'Semicolon',
          product: 'Track & Apply',
          product_access: 'Apply(Primary)',
          subscription: 'Yearly',
          amount: '20,000',
          due_date: '27/05/2021'
        },
        {
          id: 3,
          organization: 'Semicolon',
          product: 'Teach & Apply',
          product_access: 'Teach(Primary)',
          subscription: 'Yearly',
          amount: '100,000',
          due_date: '27/05/2021'
        },
        {
          id: 4,
          organization: 'Semicolon',
          product: 'Teach, Track & Apply',
          product_access: 'Track(Primary)',
          subscription: 'Yearly',
          amount: '90,000',
          due_date: '27/05/2021'
        },
        {
          id: 5,
          organization: 'Semicolon',
          product: 'Teach, Track & Apply',
          product_access: 'Teach(Primary)',
          subscription: 'Monthly',
          amount: '20,000',
          due_date: '27/05/2021'
        },
        {
          id: 6,
          organization: 'Semicolon',
          product: 'Track & Apply',
          product_access: 'Track(Primary)',
          subscription: 'Yearly',
          amount: '1,000',
          due_date: '27/05/2021'
        },


      ]

    }
  },
  methods:{
    processedDate(createdDate) {
      if (!createdDate) return;
      let day;
      let date, now
      day = createdDate.split("T")[0]
      let d1 = date = new Date(day);
      let d2 = now = new Date();
      if (d2.getTime() < d1.getTime()) {
        d1 = now;
        d2 = date;
      }
      let yd = d1.getYear();
      let yn = d2.getYear();
      let years = yn - yd;
      let md = d1.getMonth();
      let mn = d2.getMonth();
      let months = mn - md;
      if (months < 0) {
        years--;
        months = 12 - md + mn;
      }
      let dd = d1.getDate();
      let dn = d2.getDate();
      let days = dn - dd;
      if (days < 0) {
        months--;
        // figure out how many days there are in the last month
        d2.setMonth(mn, 0);
        days = d2.getDate() - dd + dn;
      }
      let weeks = Math.floor(days / 7);
      days = days % 7;
      if (years > 0) return years + 'year' +  (years > 1 ? "s" : "") + " " + "ago"
      if (months > 0) return months + ' month' +  (months > 1 ? "s" : "") + " " + "ago"
      if (weeks > 0) return weeks + ' week' +  (weeks > 1 ? "s" : "") + " " + "ago"
      if(days > 0) return  days + ' day' +  (days > 1 ? "s" : "")  + " " + "ago" ;
      return "Created Today";

    },
    help() {
      this.$router.push({name: 'Job-stepEight'})
    },
    updateJob(){
      if (this.$route.name === 'RecruiterDashboard' && this.job.status === 'Draft'){
          this.$router.push({name :"Job-stepOne",query:{jobId:this.job.id}})
      }
    }

  },
  // id: 1,
  // job_title: "Front-end",
  // experience_level: "Junior-level",
  // is_remote: false,
  // country: "Nigeria",
  // city: "Yaba",
  // state: "Lagos",
  // is_published: false,
  // is_closed: false,
  // created_at: "2021-08-17T12:43:40.272102+01:00"

}
</script>

<style scoped>
.jobTitle{
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 140%;
}
.jobLevelAndLocation{

  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: 0.01em;
}
h4,h3,p{
  text-transform: capitalize;
  font-family: DM Sans;
}
h2{
  font-family: DM Sans;
}
.status{
  border: 1px solid #eaeaea;
  border-radius: 8px;
}
.jobCard{
  box-shadow: 0px 8px 16px #F0F9FF ;
}
.jobStatus{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.jobCreated{
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: 0.01em;
}
</style>
