<template>
  <div class="profile tw-mt-8" v-if="percent<100.00">
    <div class="tw-flex tw-justify-between tw-items-center tw-flex-col md:tw-flex-row lg:tw-flex-row">
      <div class="tw-flex">
        <v-progress-circular
            :value="percent"
            color="#008EEF"
            :size="50"
        >
          <span class="percent">{{percent}}%</span>
        </v-progress-circular>
        <div class="next tw-ml-4">
          <small>Your Profile is {{percent}}% complete</small>
          <br>
          <div class="tw-flex tw-items-center tw-mt-2" v-if="filedToComplete">
            <ArrowRightCircleIcon class="icon-class tw-mr-1"/>
            <span>Next: {{filedToComplete[0]==='company_logo'?'Upload':'Tell us'}} your {{filedToComplete[0].split('_').join(' ')}}</span>

          </div>
        </div>
      </div>
      <div @click="goProfile" class="complete-profile tw-mt-3 lg:tw-mt-0">
        <h5 class="complete-text">Complete Profile</h5>
      </div>
    </div>
  </div>

</template>

<script>
import {ArrowRightCircleIcon} from 'vue-feather-icons'
export default {
name: "ProfileCompletionRate",
  components:{
    ArrowRightCircleIcon
  },
  props:{
    percent:[String,Number],
    filedToComplete:[Array,Object]
  },
  methods: {
  goProfile(){
    this.$router.push({name:'EditCompanyProfileView'})
  }
  }

}
</script>

<style scoped>
.profile{
  background: #F7FCFF;
  border-radius: 8px;
  padding: 18px;

}
.complete-profile{
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(240, 249, 255, 0.5);
  border-radius: 4px;
  cursor: pointer;
  padding: 15px;
}
.complete-text{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #475661;
}
.next small{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}
.next span{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.5);
}
.percent{
  font-family: DM Sans,serif !important;
  font-style: normal;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #2B1C1C !important;
}
.icon-class{
  color: rgba(0, 0, 0, 0.5);
  width: 13px;
  height: 13px;
}

</style>
