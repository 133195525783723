<template>
  <div>
    <div v-if="getCompanyProfile.verified === false" class="tw-mt-3 lg:tw-mt-1 md:tw-mt-1" style="margin-top: 60px!important;">
      <div class="verify-card d-flex pa-3 tw-mt-20">
        <AlertCircleIcon size="25" style="color: #008EEF" class="tw-hidden md:tw-block"/>
        <AlertCircleIcon size="65" style="color: #008EEF" class="tw-flex md:tw-hidden"/>
        <div class="ml-3">
          <span class="verify-bold">Your organization has not been verified. </span>
          <span class="verify-light">An enum admin would reach out to you via phone to verify your organization</span>
        </div>
      </div>
    </div>
    <div class="tw-mt-20 lg:tw-mt-6 md:tw-mt-6">
      <h4 style="margin-top: 60px" class="greeting">{{displayGreetings}} {{getUserFirstName}}!</h4>
    </div>
    <ProfileCompletionRate :percent="Math.round(jobInfo.profile_completed)" :filed-to-complete="jobInfo.fields_to_update"/>
    <div
        class="tw-flex tw-mt-8 tw-max-w-xs tw-overflow-x-auto lg:tw-overflow-hidden 	lg:tw-max-w-full md:tw-max-w-full">
      <v-row align-content="center" no-gutters >
        <v-col cols="12" class="tw-flex">
        <div class="details tw-cursor-pointer" @click="$router.push({name:'JobSummaryOverview'})">
          <p>Jobs</p>
          <span>{{ insight.jobs }}</span>
        </div>
        <div class="details">
          <p>Applications</p>
          <span>{{insight.applications}}</span>
        </div>
        <div class="details">
          <p>Hire</p>
          <span>{{insight.hires}}</span>
        </div>
        <div>
          <div class="tw-relative tw-hidden lg:tw-block  tw-mb-5" v-for="(action,i) in actions" :key="i">
            <img src="@/components/dashboard/reuseables/post-card.svg" width="251" alt="" style="margin-right: 20px"/>
            <div class="post">
              <h3 class="post-text" v-html="action.description"></h3>
              <br>
              <BaseButton width="112px" height="39px" @click="routeTo"  :button-text="action.name"/>
            </div>
          </div>
        </div>
        </v-col>
      </v-row>

    </div>
    <div class="apex tw-mb-10 mt-5">
      <div class="tw-flex tw-justify-between tw-w-1/2">
        <p class="applicant tw-mt-3 ">NUMBER OF APPLICATIONS PER TIME</p>
<!--        <v-btn-toggle dense v-model="toggle" color="#008EEF">-->
<!--          <v-btn @click="getTableFocus('Week')" class="text-capitalize black&#45;&#45;text font-weight-regular btn-class" :class="{'activeBtn': isActive('Week')}">Week</v-btn>-->
<!--          <v-btn @click="getTableFocus('Month')" class="text-capitalize black&#45;&#45;text font-weight-regular btn-class" :class="{'activeBtn': isActive('Month')}">Month</v-btn>-->
<!--        </v-btn-toggle>-->
      </div>
      <div class="lg:tw-w-7/12 tw-w-full">
        <v-skeleton-loader :boilerplate="true" type="table-tbody" v-if="chartLoading"></v-skeleton-loader>
      </div>
      <div v-if="!chartLoading">
        <apexchart ref="realtimeChart" :options="options" :series="series" class="lg:tw-w-7/12 tw-w-full apex-style	" type="bar"></apexchart>
      </div>
    </div>
    <div>
      <h3 class="recent">Recent Postings</h3>
      <RecentOpeningCard :job="recentJob"/>
    </div>
  </div>
</template>

<script>
import ProfileCompletionRate from "@/components/dashboard/reuseables/ProfileCompletionRate";
import RecentOpeningCard from "@/components/dashboard/reuseables/RecentOpeningCard";
import BaseButton from "@/components/UIComponents/BaseButton";
import {getJobMetrics, getRecentJob, getDashboardInsight, monthlyInsights} from "@/services/api/APIService"
import {AlertCircleIcon} from "vue-feather-icons";
import {mapGetters} from "vuex"
import VueApexCharts from "vue-apexcharts";

export default {
  name: "RecruiterHomeDashboard",
  components: {BaseButton, RecentOpeningCard,ProfileCompletionRate, AlertCircleIcon,apexchart: VueApexCharts},
  data() {
    return {
      toggle: 0,
      chartLoading: false,
      options: {
        xaxis: {
          categories: []
        },
        plotOptions:{
          bar:{
            borderRadius: 10,
            columnWidth: '20%',
          },
        },
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false
          },
            width:'5px'
        },
        colors: ['#8F47F4']
      },
      series: [{
         data: [],
      }],
      actions:[
        {
          name:' Post a Job',
          description:'Start hiring easily',
          route:''
        },
      ],
      recentJob:{},
      jobInfo:{},
      insight: {},
      monthlyData: [],
      active: 'Week'
    }
  },

  computed:{
    ...mapGetters('editCompany', ['getCompanyProfile']),
    ...mapGetters('dashboardInsights', ['weeklyData']),
    getWeekGraph() {
      return this.weeklyData.map(item => this.getDateRangeOfWeek(item.week))
    },
     getWeekCount() {
      return this.weeklyData.map(item => item.count)
    },
    getUserFirstName(){
      return  JSON.parse(sessionStorage.getItem('userData')).name.split(" ")[0]
    },
    getVerified() {
      return  JSON.parse(sessionStorage.getItem('userData')).is_company_verified
    },
    displayGreetings(){
      let myDate = new Date();
      let hrs = myDate.getHours();
      let greeting
      if (hrs < 12)
        greeting ='Good morning,';
      else if (hrs >= 12 && hrs <= 17)
        greeting = 'Good afternoon,';
      else if (hrs >= 17 && hrs <= 24)
        greeting = 'Good evening,';
      return greeting
    }

    },
  methods:{
    isActive(item) {
      return item === this.active
    },
    getProfile(){
      this.$store.dispatch('editCompany/getCompanyProfile', this.$siteName())
    },
    async getRecentJob(){
      getRecentJob(this.$siteName()).then(res=>{
        this.recentJob = res.data
      })
      .catch(err=>{
        console.log(err)
      })
    },
   async getJobInfo(){
      getJobMetrics(this.$siteName())
      .then(res=>{
        this.jobInfo = res.data
      })
      .catch(err=>{
        console.log(err)
      })
    },
    async getJobInsight(){
      await getDashboardInsight(this.$siteName())
          .then(res=>{
            this.insight = res.data
          })
          .catch(err=>{
            console.log(err)
          })
    },
    async getWeeklyInsight(){
      this.chartLoading = true
      await this.$store.dispatch('dashboardInsights/weeklyDashboard', this.$siteName()).then(
          () => {
            this.options.xaxis.categories = this.getWeekGraph
            this.series[0].data = this.getWeekCount
            this.chartLoading = false
          }
      )
      .catch(()=>{this.chartLoading = false})


    },
    async getMonthly(){
       monthlyInsights(this.$siteName())
          .then(res=>{
            this.monthlyData = res.data
            console.log(this.monthlyData)
          })
          .catch(err=>{
            console.log(err)
          })
    },
    getDateRangeOfWeek(weekNo){
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
      let d1 = new Date();
      let numOfDaysPastSinceLastMonday = eval(d1.getDay()- 1);
      d1.setDate(d1.getDate() - numOfDaysPastSinceLastMonday);
      const weekNoToday = d1.getWeek();
      const weeksInTheFuture = eval(weekNo - weekNoToday);
      d1.setDate(d1.getDate() + eval( 7 * weeksInTheFuture ));
      const rangeIsFrom = monthNames[d1.getMonth()] + " " + d1.getDate();
      d1.setDate(d1.getDate() + 6);
      const rangeIsTo = d1.getDate();
      return rangeIsFrom + " - "+rangeIsTo;
    },
    getCurrentMonth(number) {
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
      const rangeIsFrom = monthNames[number - 1];
      return rangeIsFrom
    },


    getMonthGraph() {
      return this.monthlyData.map(item => this.getCurrentMonth(item.month))
    },
    getMonthCount() {
      return this.monthlyData.map(item => item.count)
    },
    routeTo(){
      sessionStorage.removeItem('returnedSelectedDetails')
      this.$router.push({name : 'Job-stepOne'})
    }
  },
  async created() {
    await this.getRecentJob()
    await  this.getJobInfo()
    await this.getProfile()
    await this.getJobInsight()
    await this.getWeeklyInsight()
    await this.getMonthly()
  },

}
</script>

<style scoped>
.greeting {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #475661;
}

.details {
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  border-radius: 10px;
  height: 100px;
  width: 200px;
  padding: 15px;
  margin-right: 15px;
}


.details p {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #475661;
}

.details span {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #008EEF;
}

.post {
  position: absolute;
  left: 30%;
  top: 19%;
  transform: translate(-30%, -19%);
  cursor: pointer;
}

.post-text {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #142E70;
  margin-top: 10px;
}

.apex p {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #475661;
}

.apex-style {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: #4F4F4F;

}
.recent{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #475661;
}
.applicant{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #475661;
  margin-left: 12px;
}
.verify-card {
  background: #F7FCFF;
  border: 1px solid #C5E7FF;
  box-sizing: border-box;
  border-radius: 8px;
}
.verify-bold {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #1E323F;
}
.verify-light {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #1E323F;
}
.activeBtn {
  background-color:  rgba(0, 142, 239, 0.08) !important;
  color: black;
}
.btn-class {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
</style>
