<template>
  <div>
    <div v-if="Object.keys(job).length">
      <JobCard :job="job" />
    </div>
            <div v-else>
              <h1 class="no-recent">You don’t have any job listing</h1>
            </div>

  </div>
</template>

<script>
import JobCard from "@/components/viewAdminJobs/JobCard";
export default {
name: "RecentOpeningCard",
  components: {JobCard},
  props:{
    job:[Object,Array]
  },
}
</script>

<style scoped>
.recent-div{
  background: #FFFFFF;
  border: 1px solid #F7FCFF;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px rgba(240, 249, 255, 0.5);
  border-radius: 8px;
}
.live{
  border: 1px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 6px;
}
.live span{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #4F4F4F;
}
.created{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4F4F4F;
}

.recent-div h3{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #4F4F4F;

}
.recent-div h4{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  align-items: center;
  color: #4F4F4F;
}
.no-recent{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #9CABB5;

}

</style>
